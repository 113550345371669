var commonjsGlobal = typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

var e,goog=goog||{},h=commonjsGlobal;function l(a){return"string"==typeof a}function m(a,b){a=a.split(".");b=b||h;for(var c=0;c<a.length;c++)if(b=b[a[c]], null==b)return null;return b}function aa(){}
function ba(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return"array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return"object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return"array";if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return"function"}else return"null";
else if("function"==b&&"undefined"==typeof a.call)return"object";return b}function n(a){return"array"==ba(a)}function ca(a){var b=ba(a);return"array"==b||"object"==b&&"number"==typeof a.length}function q(a){return"function"==ba(a)}function r(a){var b=typeof a;return"object"==b&&null!=a||"function"==b}var t="closure_uid_"+(1E9*Math.random()>>>0),da=0;function ea(a,b,c){return a.call.apply(a.bind,arguments)}
function fa(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}}function u(a,b,c){u=Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?ea:fa;return u.apply(null,arguments)}
function v(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}}var w=Date.now||function(){return+new Date};function x(a,b){function c(){}c.prototype=b.prototype;a.L=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.mh=function(a,c,g){for(var d=Array(arguments.length-2),f=2;f<arguments.length;f++)d[f-2]=arguments[f];return b.prototype[c].apply(a,d)};}function y(a){if(Error.captureStackTrace)Error.captureStackTrace(this,y);else{var b=Error().stack;b&&(this.stack=b);}a&&(this.message=String(a));}x(y,Error);y.prototype.name="CustomError";function ha(a,b){a=a.split("%s");for(var c="",d=a.length-1,f=0;f<d;f++)c+=a[f]+(f<b.length?b[f]:"%s");y.call(this,c+a[d]);}x(ha,y);ha.prototype.name="AssertionError";function ia(a,b){throw new ha("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1));}function z(){0!=ja&&(ka[this[t]||(this[t]=++da)]=this);this.Ka=this.Ka;this.Qa=this.Qa;}var ja=0,ka={};z.prototype.Ka=!1;z.prototype.bb=function(){if(!this.Ka&&(this.Ka=!0, this.F(), 0!=ja)){var a=this[t]||(this[t]=++da);if(0!=ja&&this.Qa&&0<this.Qa.length)throw Error(this+" did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method.");delete ka[a];}};z.prototype.F=function(){if(this.Qa)for(;this.Qa.length;)this.Qa.shift()();};var la=Array.prototype.indexOf?function(a,b){return Array.prototype.indexOf.call(a,b,void 0)}:function(a,b){if(l(a))return l(b)&&1==b.length?a.indexOf(b,0):-1;for(var c=0;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1},ma=Array.prototype.lastIndexOf?function(a,b){return Array.prototype.lastIndexOf.call(a,b,a.length-1)}:function(a,b){var c=a.length-1;0>c&&(c=Math.max(0,a.length+c));if(l(a))return l(b)&&1==b.length?a.lastIndexOf(b,c):-1;for(;0<=c;c--)if(c in a&&a[c]===b)return c;return-1},na=
Array.prototype.forEach?function(a,b,c){Array.prototype.forEach.call(a,b,c);}:function(a,b,c){for(var d=a.length,f=l(a)?a.split(""):a,g=0;g<d;g++)g in f&&b.call(c,f[g],g,a);},oa=Array.prototype.some?function(a,b){return Array.prototype.some.call(a,b,void 0)}:function(a,b){for(var c=a.length,d=l(a)?a.split(""):a,f=0;f<c;f++)if(f in d&&b.call(void 0,d[f],f,a))return!0;return!1};
function pa(a){a:{var b=qa;for(var c=a.length,d=l(a)?a.split(""):a,f=0;f<c;f++)if(f in d&&b.call(void 0,d[f],f,a)){b=f;break a}b=-1;}return 0>b?null:l(a)?a.charAt(b):a[b]}function ra(a){if(!n(a))for(var b=a.length-1;0<=b;b--)delete a[b];a.length=0;}function sa(a,b){b=la(a,b);var c;(c=0<=b)&&Array.prototype.splice.call(a,b,1);return c}function ta(a){return Array.prototype.concat.apply([],arguments)}function ua(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return[]}function va(a){return/^[\s\xa0]*$/.test(a)}var wa=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};function xa(a,b){return a<b?-1:a>b?1:0}var A;a:{var ya=h.navigator;if(ya){var za=ya.userAgent;if(za){A=za;break a}}A="";}function B(a){return-1!=A.indexOf(a)}function Aa(a,b,c){for(var d in a)b.call(c,a[d],d,a);}function Ba(a){var b=[],c=0,d;for(d in a)b[c++]=a[d];return b}function Ca(a){var b=[],c=0,d;for(d in a)b[c++]=d;return b}function Da(a){var b={},c;for(c in a)b[c]=a[c];return b}var Ea="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
function Fa(a,b){for(var c,d,f=1;f<arguments.length;f++){d=arguments[f];for(c in d)a[c]=d[c];for(var g=0;g<Ea.length;g++)c=Ea[g], Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c]);}}function Ga(a){Ga[" "](a);return a}Ga[" "]=aa;function Ha(a,b){var c=Ia;return Object.prototype.hasOwnProperty.call(c,a)?c[a]:c[a]=b(a)}var Ja=B("Opera"),C=B("Trident")||B("MSIE"),Ka=B("Edge"),La=Ka||C,Ma=B("Gecko")&&!(-1!=A.toLowerCase().indexOf("webkit")&&!B("Edge"))&&!(B("Trident")||B("MSIE"))&&!B("Edge"),Na=-1!=A.toLowerCase().indexOf("webkit")&&!B("Edge");function Oa(){var a=h.document;return a?a.documentMode:void 0}var Pa;
a:{var Qa="",Ra=function(){var a=A;if(Ma)return/rv:([^\);]+)(\)|;)/.exec(a);if(Ka)return/Edge\/([\d\.]+)/.exec(a);if(C)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(Na)return/WebKit\/(\S+)/.exec(a);if(Ja)return/(?:Version)[ \/]?(\S+)/.exec(a)}();Ra&&(Qa=Ra?Ra[1]:"");if(C){var Sa=Oa();if(null!=Sa&&Sa>parseFloat(Qa)){Pa=String(Sa);break a}}Pa=Qa;}var Ia={};
function Ta(a){return Ha(a,function(){for(var b=0,c=wa(String(Pa)).split("."),d=wa(String(a)).split("."),f=Math.max(c.length,d.length),g=0;0==b&&g<f;g++){var k=c[g]||"",p=d[g]||"";do{k=/(\d*)(\D*)(.*)/.exec(k)||["","","",""];p=/(\d*)(\D*)(.*)/.exec(p)||["","","",""];if(0==k[0].length&&0==p[0].length)break;b=xa(0==k[1].length?0:parseInt(k[1],10),0==p[1].length?0:parseInt(p[1],10))||xa(0==k[2].length,0==p[2].length)||xa(k[2],p[2]);k=k[3];p=p[3];}while(0==b)}return 0<=b})}var Ua;var Va=h.document;
Ua=Va&&C?Oa()||("CSS1Compat"==Va.compatMode?parseInt(Pa,10):5):void 0;var Wa=Object.freeze||function(a){return a};var Xa=!C||9<=Number(Ua),Ya=C&&!Ta("9"),Za=function(){if(!h.addEventListener||!Object.defineProperty)return!1;var a=!1,b=Object.defineProperty({},"passive",{get:function(){a=!0;}});try{h.addEventListener("test",aa,b), h.removeEventListener("test",aa,b);}catch(c){}return a}();function D(a,b){this.type=a;this.currentTarget=this.target=b;this.defaultPrevented=this.Ea=!1;this.Be=!0;}D.prototype.stopPropagation=function(){this.Ea=!0;};D.prototype.preventDefault=function(){this.defaultPrevented=!0;this.Be=!1;};function E(a,b){D.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.state=null;this.pointerId=0;this.pointerType="";this.fb=null;a&&this.Kf(a,b);}x(E,D);var $a=Wa({2:"touch",3:"pen",4:"mouse"});
E.prototype.Kf=function(a,b){var c=this.type=a.type,d=a.changedTouches?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;if(b=a.relatedTarget){if(Ma){a:{try{Ga(b.nodeName);var f=!0;break a}catch(g){}f=!1;}f||(b=null);}}else"mouseover"==c?b=a.fromElement:"mouseout"==c&&(b=a.toElement);this.relatedTarget=b;null===d?(this.offsetX=Na||void 0!==a.offsetX?a.offsetX:a.layerX, this.offsetY=Na||void 0!==a.offsetY?a.offsetY:a.layerY, this.clientX=void 0!==a.clientX?a.clientX:a.pageX, this.clientY=void 0!==a.clientY?a.clientY:a.pageY, this.screenX=a.screenX||0, this.screenY=a.screenY||0):(this.clientX=void 0!==d.clientX?d.clientX:d.pageX, this.clientY=void 0!==d.clientY?d.clientY:d.pageY, this.screenX=d.screenX||0, this.screenY=d.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.pointerId=a.pointerId||0;this.pointerType=
l(a.pointerType)?a.pointerType:$a[a.pointerType]||"";this.state=a.state;this.fb=a;a.defaultPrevented&&this.preventDefault();};E.prototype.stopPropagation=function(){E.L.stopPropagation.call(this);this.fb.stopPropagation?this.fb.stopPropagation():this.fb.cancelBubble=!0;};E.prototype.preventDefault=function(){E.L.preventDefault.call(this);var a=this.fb;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1, Ya)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1;}catch(b){}};var ab="closure_listenable_"+(1E6*Math.random()|0);function F(a){return!(!a||!a[ab])}var bb=0;function cb(a,b,c,d,f){this.listener=a;this.proxy=null;this.src=b;this.type=c;this.capture=!!d;this.Ob=f;this.key=++bb;this.Sa=this.Eb=!1;}cb.prototype.Vb=function(){this.Sa=!0;this.Ob=this.src=this.proxy=this.listener=null;};function db(a){this.src=a;this.J={};this.xb=0;}e=db.prototype;e.add=function(a,b,c,d,f){var g=a.toString();a=this.J[g];a||(a=this.J[g]=[], this.xb++);var k=eb(a,b,d,f);-1<k?(b=a[k], c||(b.Eb=!1)):(b=new cb(b,this.src,g,!!d,f), b.Eb=c, a.push(b));return b};e.remove=function(a,b,c,d){a=a.toString();if(!(a in this.J))return!1;var f=this.J[a];b=eb(f,b,c,d);return-1<b?(f[b].Vb(), Array.prototype.splice.call(f,b,1), 0==f.length&&(delete this.J[a], this.xb--), !0):!1};
e.ye=function(a){var b=a.type;b in this.J&&sa(this.J[b],a)&&(a.Vb(), 0==this.J[b].length&&(delete this.J[b], this.xb--));};e.pb=function(a){a=a&&a.toString();var c;for(c in this.J)if(!a||c==a){for(var d=this.J[c],f=0;f<d.length;f++)d[f].Vb();delete this.J[c];this.xb--;}};e.jb=function(a,b,c,d){a=this.J[a.toString()];var f=-1;a&&(f=eb(a,b,c,d));return-1<f?a[f]:null};function eb(a,b,c,d){for(var f=0;f<a.length;++f){var g=a[f];if(!g.Sa&&g.listener==b&&g.capture==!!c&&g.Ob==d)return f}return-1}var fb="closure_lm_"+(1E6*Math.random()|0),gb={};function ib(a,b,c,d,f){if(d&&d.once)return jb(a,b,c,d,f);if(n(b)){for(var g=0;g<b.length;g++)ib(a,b[g],c,d,f);return null}c=kb(c);return F(a)?a.nb(b,c,r(d)?!!d.capture:!!d,f):lb(a,b,c,!1,d,f)}
function lb(a,b,c,d,f,g){if(!b)throw Error("Invalid event type");var k=r(f)?!!f.capture:!!f,p=G(a);p||(a[fb]=p=new db(a));c=p.add(b,c,d,k,g);if(c.proxy)return c;d=mb();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)Za||(f=k), void 0===f&&(f=!1), a.addEventListener(b.toString(),d,f);else if(a.attachEvent)a.attachEvent(nb(b.toString()),d);else if(a.addListener&&a.removeListener)a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");return c}
function mb(){var a=ob,b=Xa?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b}function jb(a,b,c,d,f){if(n(b)){for(var g=0;g<b.length;g++)jb(a,b[g],c,d,f);return null}c=kb(c);return F(a)?a.Oc(b,c,r(d)?!!d.capture:!!d,f):lb(a,b,c,!0,d,f)}function pb(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)pb(a,b[g],c,d,f);else d=r(d)?!!d.capture:!!d, c=kb(c), F(a)?a.ed(b,c,d,f):a&&(a=G(a))&&(b=a.jb(b,c,d,f))&&qb(b);}
function qb(a){if("number"!=typeof a&&a&&!a.Sa){var b=a.src;if(F(b))b.Le(a);else{var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent?b.detachEvent(nb(c),d):b.addListener&&b.removeListener&&b.removeListener(d);(c=G(b))?(c.ye(a), 0==c.xb&&(c.src=null, b[fb]=null)):a.Vb();}}}function nb(a){return a in gb?gb[a]:gb[a]="on"+a}
function rb(a,b,c,d){var f=!0;if(a=G(a))if(b=a.J[b.toString()])for(b=b.concat(), a=0;a<b.length;a++){var g=b[a];g&&g.capture==c&&!g.Sa&&(g=sb(g,d), f=f&&!1!==g);}return f}function sb(a,b){var c=a.listener,d=a.Ob||a.src;a.Eb&&qb(a);return c.call(d,b)}
function ob(a,b){if(a.Sa)return!0;if(!Xa){var c=b||m("window.event");b=new E(c,this);var d=!0;if(!(0>c.keyCode||void 0!=c.returnValue)){a:{var f=!1;if(0==c.keyCode)try{c.keyCode=-1;break a}catch(k){f=!0;}if(f||void 0==c.returnValue)c.returnValue=!0;}c=[];for(f=b.currentTarget;f;f=f.parentNode)c.push(f);a=a.type;for(f=c.length-1;!b.Ea&&0<=f;f--){b.currentTarget=c[f];var g=rb(c[f],a,!0,b);d=d&&g;}for(f=0;!b.Ea&&f<c.length;f++)b.currentTarget=c[f], g=rb(c[f],a,!1,b), d=d&&g;}return d}return sb(a,new E(b,this))}
function G(a){a=a[fb];return a instanceof db?a:null}var tb="__closure_events_fn_"+(1E9*Math.random()>>>0);function kb(a){if(q(a))return a;a[tb]||(a[tb]=function(b){return a.handleEvent(b)});return a[tb]}function H(){z.call(this);this.ka=new db(this);this.Pe=this;this.Uc=null;}x(H,z);H.prototype[ab]=!0;e=H.prototype;e.addEventListener=function(a,b,c,d){ib(this,a,b,c,d);};e.removeEventListener=function(a,b,c,d){pb(this,a,b,c,d);};
e.dispatchEvent=function(a){var b,c=this.Uc;if(c)for(b=[];c;c=c.Uc)b.push(c);c=this.Pe;var d=a.type||a;if(l(a))a=new D(a,c);else if(a instanceof D)a.target=a.target||c;else{var f=a;a=new D(d,c);Fa(a,f);}f=!0;if(b)for(var g=b.length-1;!a.Ea&&0<=g;g--){var k=a.currentTarget=b[g];f=k.Lb(d,!0,a)&&f;}a.Ea||(k=a.currentTarget=c, f=k.Lb(d,!0,a)&&f, a.Ea||(f=k.Lb(d,!1,a)&&f));if(b)for(g=0;!a.Ea&&g<b.length;g++)k=a.currentTarget=b[g], f=k.Lb(d,!1,a)&&f;return f};
e.F=function(){H.L.F.call(this);this.pg();this.Uc=null;};e.nb=function(a,b,c,d){return this.ka.add(String(a),b,!1,c,d)};e.Oc=function(a,b,c,d){return this.ka.add(String(a),b,!0,c,d)};e.ed=function(a,b,c,d){this.ka.remove(String(a),b,c,d);};e.Le=function(a){this.ka.ye(a);};e.pg=function(){this.ka&&this.ka.pb(void 0);};
e.Lb=function(a,b,c){a=this.ka.J[String(a)];if(!a)return!0;a=a.concat();for(var d=!0,f=0;f<a.length;++f){var g=a[f];if(g&&!g.Sa&&g.capture==b){var k=g.listener,p=g.Ob||g.src;g.Eb&&this.Le(g);d=!1!==k.call(p,c)&&d;}}return d&&0!=c.Be};e.jb=function(a,b,c,d){return this.ka.jb(String(a),b,c,d)};var ub=h.JSON.stringify;function vb(a,b){this.Sf=100;this.ef=a;this.ug=b;this.Zb=0;this.Pb=null;}vb.prototype.get=function(){if(0<this.Zb){this.Zb--;var a=this.Pb;this.Pb=a.next;a.next=null;}else a=this.ef();return a};vb.prototype.put=function(a){this.ug(a);this.Zb<this.Sf&&(this.Zb++, a.next=this.Pb, this.Pb=a);};function I(){this.lc=this.Va=null;}var xb=new vb(function(){return new wb},function(a){a.reset();});I.prototype.add=function(a,b){var c=this.Af();c.set(a,b);this.lc?this.lc.next=c:this.Va=c;this.lc=c;};I.prototype.remove=function(){var a=null;this.Va&&(a=this.Va, this.Va=this.Va.next, this.Va||(this.lc=null), a.next=null);return a};I.prototype.wg=function(a){xb.put(a);};I.prototype.Af=function(){return xb.get()};function wb(){this.next=this.scope=this.Gc=null;}
wb.prototype.set=function(a,b){this.Gc=a;this.scope=b;this.next=null;};wb.prototype.reset=function(){this.next=this.scope=this.Gc=null;};function yb(a){h.setTimeout(function(){throw a;},0);}var zb;
function Ab(){var a=h.MessageChannel;"undefined"===typeof a&&"undefined"!==typeof window&&window.postMessage&&window.addEventListener&&!B("Presto")&&(a=function(){var a=document.createElement("IFRAME");a.style.display="none";a.src="";document.documentElement.appendChild(a);var b=a.contentWindow;a=b.document;a.open();a.write("");a.close();var c="callImmediate"+Math.random(),d="file:"==b.location.protocol?"*":b.location.protocol+"//"+b.location.host;a=u(function(a){if(("*"==d||a.origin==d)&&a.data==
c)this.port1.onmessage();},this);b.addEventListener("message",a,!1);this.port1={};this.port2={postMessage:function(){b.postMessage(c,d);}};});if("undefined"!==typeof a&&!B("Trident")&&!B("MSIE")){var b=new a,c={},d=c;b.port1.onmessage=function(){if(void 0!==c.next){c=c.next;var a=c.rd;c.rd=null;a();}};return function(a){d.next={rd:a};d=d.next;b.port2.postMessage(0);}}return"undefined"!==typeof document&&"onreadystatechange"in document.createElement("SCRIPT")?function(a){var b=document.createElement("SCRIPT");
b.onreadystatechange=function(){b.onreadystatechange=null;b.parentNode.removeChild(b);b=null;a();a=null;};document.documentElement.appendChild(b);}:function(a){h.setTimeout(a,0);}}var Bb;function Cb(){if(h.Promise&&h.Promise.resolve){var a=h.Promise.resolve(void 0);Bb=function(){a.then(Db);};}else Bb=function(){var a=Db;!q(h.setImmediate)||h.Window&&h.Window.prototype&&!B("Edge")&&h.Window.prototype.setImmediate==h.setImmediate?(zb||(zb=Ab()), zb(a)):h.setImmediate(a);};}var Eb=!1,Fb=new I;function Db(){for(var a;a=Fb.remove();){try{a.Gc.call(a.scope);}catch(b){yb(b);}Fb.wg(a);}Eb=!1;}function Gb(a,b){H.call(this);this.Na=a||1;this.wb=b||h;this.nd=u(this.Rg,this);this.ie=w();}x(Gb,H);e=Gb.prototype;e.enabled=!1;e.B=null;e.setInterval=function(a){this.Na=a;this.B&&this.enabled?(this.stop(), this.start()):this.B&&this.stop();};e.Rg=function(){if(this.enabled){var a=w()-this.ie;0<a&&a<.8*this.Na?this.B=this.wb.setTimeout(this.nd,this.Na-a):(this.B&&(this.wb.clearTimeout(this.B), this.B=null), this.ff(), this.enabled&&(this.stop(), this.start()));}};e.ff=function(){this.dispatchEvent("tick");};
e.start=function(){this.enabled=!0;this.B||(this.B=this.wb.setTimeout(this.nd,this.Na), this.ie=w());};e.stop=function(){this.enabled=!1;this.B&&(this.wb.clearTimeout(this.B), this.B=null);};e.F=function(){Gb.L.F.call(this);this.stop();delete this.wb;};function Hb(a,b,c){if(q(a))c&&(a=u(a,c));else if(a&&"function"==typeof a.handleEvent)a=u(a.handleEvent,a);else throw Error("Invalid listener argument");return 2147483647<Number(b)?-1:h.setTimeout(a,b||0)}function Ib(a,b,c){z.call(this);this.Uf=null!=c?u(a,c):a;this.Na=b;this.Xe=u(this.fg,this);this.qc=[];}x(Ib,z);e=Ib.prototype;e.Ta=!1;e.ob=0;e.B=null;e.mf=function(a){this.qc=arguments;this.B||this.ob?this.Ta=!0:this.Cc();};e.stop=function(){this.B&&(h.clearTimeout(this.B), this.B=null, this.Ta=!1, this.qc=[]);};e.pause=function(){this.ob++;};e.resume=function(){this.ob--;this.ob||!this.Ta||this.B||(this.Ta=!1, this.Cc());};e.F=function(){Ib.L.F.call(this);this.stop();};
e.fg=function(){this.B=null;this.Ta&&!this.ob&&(this.Ta=!1, this.Cc());};e.Cc=function(){this.B=Hb(this.Xe,this.Na);this.Uf.apply(null,this.qc);};function Jb(a){z.call(this);this.i=a;this.o={};}x(Jb,z);var Kb=[];e=Jb.prototype;e.nb=function(a,b,c,d){return this.Tf(a,b,c,d)};e.Tf=function(a,b,c,d){n(b)||(b&&(Kb[0]=b.toString()), b=Kb);for(var f=0;f<b.length;f++){var g=ib(a,b[f],c||this.handleEvent,d||!1,this.i||this);if(!g)break;this.o[g.key]=g;}return this};e.Oc=function(a,b,c,d){return this.je(a,b,c,d)};
e.je=function(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)this.je(a,b[g],c,d,f);else{a=jb(a,b,c||this.handleEvent,d,f||this.i||this);if(!a)return this;this.o[a.key]=a;}return this};e.ed=function(a,b,c,d,f){if(n(b))for(var g=0;g<b.length;g++)this.ed(a,b[g],c,d,f);else c=c||this.handleEvent, d=r(d)?!!d.capture:!!d, f=f||this.i||this, c=kb(c), d=!!d, b=F(a)?a.jb(b,c,d,f):a?(a=G(a))?a.jb(b,c,d,f):null:null, b&&(qb(b), delete this.o[b.key]);};
e.pb=function(){Aa(this.o,function(a,b){this.o.hasOwnProperty(b)&&qb(a);},this);this.o={};};e.F=function(){Jb.L.F.call(this);this.pb();};e.handleEvent=function(){throw Error("EventHandler.handleEvent not implemented");};function J(a,b,c){this.reset(a,b,c,void 0,void 0);}J.prototype.Md=null;J.prototype.reset=function(a,b,c,d,f){this.mb=a;delete this.Md;};J.prototype.Bg=function(a){this.Md=a;};J.prototype.Ge=function(a){this.mb=a;};function Mb(a){this.pe=a;this.Zd=this.uc=this.mb=this.$b=null;}function K(a,b){this.name=a;this.value=b;}K.prototype.toString=function(){return this.name};var Nb=new K("SEVERE",1E3),Ob=new K("WARNING",900),Pb=new K("INFO",800),Qb=new K("CONFIG",700),Rb=new K("FINE",500);e=Mb.prototype;e.getName=function(){return this.pe};e.getParent=function(){return this.$b};e.pf=function(){this.uc||(this.uc={});return this.uc};e.Ge=function(a){this.mb=a;};
e.Qd=function(){if(this.mb)return this.mb;if(this.$b)return this.$b.Qd();ia("Root logger has no level set.");return null};e.Pf=function(a){return a.value>=this.Qd().value};e.log=function(a,b,c){this.Pf(a)&&(q(b)&&(b=b()), this.gf(this.uf(a,b,c)));};e.uf=function(a,b,c){a=new J(a,String(b),this.pe);c&&a.Bg(c);return a};e.ca=function(a,b){this.log(Nb,a,b);};e.T=function(a,b){this.log(Ob,a,b);};e.info=function(a,b){this.log(Pb,a,b);};e.lf=function(a){this.log(Rb,a,void 0);};
e.gf=function(a){for(var b=this;b;)b.We(a), b=b.getParent();};e.We=function(a){if(this.Zd)for(var b=0,c;c=this.Zd[b];b++)c(a);};e.Fg=function(a){this.$b=a;};e.Qe=function(a,b){this.pf()[a]=b;};var Sb={},Tb=null;function Vb(a){Tb||(Tb=new Mb(""), Sb[""]=Tb, Tb.Ge(Qb));var b;if(!(b=Sb[a])){b=new Mb(a);var c=a.lastIndexOf("."),d=a.substr(c+1);c=Vb(a.substr(0,c));c.Qe(d,b);b.Fg(c);Sb[a]=b;}return b}function Wb(a,b){a&&a.info(b,void 0);}function L(a,b){a&&a.lf(b);}function Xb(){this.s=Vb("goog.labs.net.webChannel.WebChannelDebug");this.Wc=!0;}e=Xb.prototype;e.Id=function(){this.Wc=!1;};e.Tg=function(a,b,c,d,f){var g=this;this.info(function(){return"XMLHTTP REQ ("+c+") [attempt "+d+"]: "+a+"\n"+b+"\n"+g.Xf(f)});};e.Ug=function(a,b,c,d,f,g){this.info(function(){return"XMLHTTP RESP ("+c+") [ attempt "+d+"]: "+a+"\n"+b+"\n"+f+" "+g});};e.Wa=function(a,b,c){var d=this;this.info(function(){return"XMLHTTP TEXT ("+a+"): "+d.ng(b)+(c?" "+c:"")});};
e.Sg=function(a){this.info(function(){return"TIMEOUT: "+a});};e.debug=function(a){L(this.s,a);};e.cb=function(a,b){var c=this.s;c&&c.ca(b||"Exception",a);};e.info=function(a){Wb(this.s,a);};e.T=function(a){var b=this.s;b&&b.T(a,void 0);};e.ca=function(a){var b=this.s;b&&b.ca(a,void 0);};
e.ng=function(a){if(!this.Wc)return a;if(!a)return null;try{var b=JSON.parse(a);if(b)for(var c=0;c<b.length;c++)n(b[c])&&this.Wf(b[c]);return ub(b)}catch(d){return this.debug("Exception parsing expected JS array - probably was not JS"), a}};e.Wf=function(a){if(!(2>a.length||(a=a[1], !n(a)||1>a.length))){var b=a[0];if("noop"!=b&&"stop"!=b&&"close"!=b)for(b=1;b<a.length;b++)a[b]="";}};
e.Xf=function(a){if(!this.Wc)return a;if(!a)return null;var b="";a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].split("=");if(1<d.length){var f=d[0];d=d[1];var g=f.split("_");b=2<=g.length&&"type"==g[1]?b+(f+"="+d+"&"):b+(f+"=redacted&");}}return b};var M=new H;function Yb(a){D.call(this,"serverreachability",a);}x(Yb,D);function N(a){M.dispatchEvent(new Yb(M,a));}function Zb(a,b){D.call(this,"statevent",a);this.stat=b;}x(Zb,D);function O(a){M.dispatchEvent(new Zb(M,a));}function $b(a,b,c){D.call(this,"timingevent",a);this.size=b;this.rtt=c;}x($b,D);function ac(a,b,c){M.dispatchEvent(new $b(M,a,b,c));}function P(a,b){if(!q(a))throw Error("Fn must not be null and must be a function");return h.setTimeout(function(){a();},b)}var bc={NO_ERROR:0,Vg:1,bh:2,ah:3,Yg:4,$g:5,dh:6,Ne:7,TIMEOUT:8,gh:9};var cc={Xg:"complete",kh:"success",Oe:"error",Ne:"abort",ih:"ready",jh:"readystatechange",TIMEOUT:"timeout",eh:"incrementaldata",hh:"progress",Zg:"downloadprogress",lh:"uploadprogress"};function dc(){}dc.prototype.pd=null;dc.prototype.Vd=function(){return this.pd||(this.pd=this.Mf())};function ec(){}var Q={OPEN:"a",Wg:"b",Oe:"c",fh:"d"};function fc(){D.call(this,"d");}x(fc,D);function gc(){D.call(this,"c");}x(gc,D);var hc;function ic(){}x(ic,dc);ic.prototype.Dd=function(){var a=this.Wd();return a?new ActiveXObject(a):new XMLHttpRequest};ic.prototype.Mf=function(){var a={};this.Wd()&&(a[0]=!0, a[1]=!0);return a};
ic.prototype.Wd=function(){if(!this.be&&"undefined"==typeof XMLHttpRequest&&"undefined"!=typeof ActiveXObject){for(var a=["MSXML2.XMLHTTP.6.0","MSXML2.XMLHTTP.3.0","MSXML2.XMLHTTP","Microsoft.XMLHTTP"],b=0;b<a.length;b++){var c=a[b];try{return new ActiveXObject(c), this.be=c}catch(d){}}throw Error("Could not create ActiveXObject. ActiveX might be disabled, or MSXML might not be installed");}return this.be};hc=new ic;function R(a,b,c,d,f){this.b=a;this.a=b;this.ra=c;this.R=d;this.Xc=f||1;this.Fc=new Jb(this);this.Ua=jc;a=La?125:void 0;this.Vc=new Gb(a);this.A=null;this.S=!1;this.Da=this.pa=this.ua=this.ic=this.qb=this.hd=this.Ga=null;this.ba=[];this.h=null;this.Bb=0;this.I=this.Fa=null;this.w=-1;this.Za=!1;this.Ra=0;this.ac=null;this.lb=this.Ed=this.yc=!1;}var jc=45E3;
function kc(a,b){switch(a){case 0:return"Non-200 return code ("+b+")";case 1:return"XMLHTTP failure (no data)";case 2:return"HttpConnection timeout";default:return"Unknown error"}}var lc={},mc={};e=R.prototype;e.ga=function(a){this.A=a;};e.setTimeout=function(a){this.Ua=a;};e.He=function(a){this.Ra=a;};e.Gg=function(a){this.ba=a;};e.la=function(){return this.ba};e.kd=function(a,b){this.ic=1;this.ua=a.clone().Ub();this.Da=b;this.yc=!0;this.Ce(null);};
e.jd=function(a,b,c){this.ic=1;this.ua=a.clone().Ub();this.Da=null;this.yc=b;this.Ce(c);};
e.Ce=function(a){this.qb=w();this.eb();this.pa=this.ua.clone();this.pa.dc("t",this.Xc);this.Bb=0;this.h=this.b.Jb(this.b.fc()?a:null);0<this.Ra&&(this.ac=new Ib(u(this.Me,this,this.h),this.Ra));this.Fc.nb(this.h,"readystatechange",this.mg);a=this.A?Da(this.A):{};this.Da?(this.Fa||(this.Fa="POST"), a["Content-Type"]="application/x-www-form-urlencoded", this.h.send(this.pa,this.Fa,this.Da,a)):(this.Fa="GET", this.h.send(this.pa,this.Fa,null,a));N(1);this.a.Tg(this.Fa,this.pa,this.R,this.Xc,this.Da);};
e.mg=function(a){a=a.target;var b=this.ac;b&&3==a.ma()?(this.a.debug("Throttling readystatechange."), b.mf()):this.Me(a);};e.Me=function(a){try{a==this.h?this.hg():this.a.T("Called back with an unexpected xmlhttp");}catch(c){if(this.a.debug("Failed call to OnXmlHttpReadyStateChanged_"), this.h&&this.h.ya()){var b=this;this.a.cb(c,function(){return"ResponseText: "+b.h.ya()});}else this.a.cb(c,"No response text");}finally{}};
e.hg=function(){var a=this.h.ma(),b=this.h.Ud(),c=this.h.za();if(!(3>a||3==a&&!La&&!this.h.ya())){this.Za||4!=a||7==b||(8==b||0>=c?N(3):N(2));this.Fb();var d=this.h.za();this.w=d;b=this.h.ya();if(!b){var f=this;this.a.debug(function(){return"No response text for uri "+f.pa+" status "+d});}this.S=200==d;this.a.Ug(this.Fa,this.pa,this.R,this.Xc,a,d);if(this.S){if(this.Ig())if(c=this.sf())this.a.Wa(this.R,c,"Initial handshake response via X-HTTP-Initial-Response"), this.lb=!0, this.Yc(c);else{this.S=!1;
this.I=3;O(12);this.a.T("XMLHTTP Missing X_HTTP_INITIAL_RESPONSE ("+this.R+")");this.Ia();this.Kb();return}this.yc?(this.Fd(a,b), La&&this.S&&3==a&&this.Ng()):(this.a.Wa(this.R,b,null), this.Yc(b));4==a&&this.Ia();this.S&&!this.Za&&(4==a?this.b.Tc(this):(this.S=!1, this.eb()));}else 400==d&&0<b.indexOf("Unknown SID")?(this.I=3, O(12), this.a.T("XMLHTTP Unknown SID ("+this.R+")")):(this.I=0, O(13), this.a.T("XMLHTTP Bad status "+d+" ("+this.R+")")), this.Ia(), this.Kb();}};e.Ig=function(){return this.Ed&&!this.lb};
e.sf=function(){if(this.h){var a=this.h.kb("X-HTTP-Initial-Response");if(a&&!va(a))return a}return null};e.Ag=function(){this.Ed=!0;};
e.Fd=function(a,b){for(var c=!0;!this.Za&&this.Bb<b.length;){var d=this.vf(b);if(d==mc){4==a&&(this.I=4, O(14), c=!1);this.a.Wa(this.R,null,"[Incomplete Response]");break}else if(d==lc){this.I=4;O(15);this.a.Wa(this.R,b,"[Invalid Chunk]");c=!1;break}else this.a.Wa(this.R,d,null), this.Yc(d);}4==a&&0==b.length&&(this.I=1, O(16), c=!1);this.S=this.S&&c;c||(this.a.Wa(this.R,b,"[Invalid Chunked Response]"), this.Ia(), this.Kb());};
e.kg=function(){if(this.h){var a=this.h.ma(),b=this.h.ya();this.Bb<b.length&&(this.Fb(), this.Fd(a,b), this.S&&4!=a&&this.eb());}};e.Ng=function(){this.Fc.nb(this.Vc,"tick",this.kg);this.Vc.start();};e.vf=function(a){var b=this.Bb,c=a.indexOf("\n",b);if(-1==c)return mc;b=Number(a.substring(b,c));if(isNaN(b))return lc;c+=1;if(c+b>a.length)return mc;a=a.substr(c,b);this.Bb=c+b;return a};
e.yg=function(a){this.ic=2;this.ua=a.clone().Ub();a=!1;h.navigator&&h.navigator.sendBeacon&&(a=h.navigator.sendBeacon(this.ua.toString(),""));!a&&h.Image&&((new Image).src=this.ua, a=!0);a||(this.h=this.b.Jb(null), this.h.send(this.ua));this.qb=w();this.eb();};e.cancel=function(){this.Za=!0;this.Ia();};e.tg=function(a){a&&this.setTimeout(a);this.Ga&&(this.Fb(), this.eb());};e.eb=function(){this.hd=w()+this.Ua;this.Ke(this.Ua);};
e.Ke=function(a){if(null!=this.Ga)throw Error("WatchDog timer not null");this.Ga=P(u(this.gg,this),a);};e.Fb=function(){this.Ga&&(h.clearTimeout(this.Ga), this.Ga=null);};e.gg=function(){this.Ga=null;var a=w();0<=a-this.hd?this.Df():(this.a.T("WatchDog timer called too early"), this.Ke(this.hd-a));};e.Df=function(){this.S&&this.a.ca("Received watchdog timeout even though request loaded successfully");this.a.Sg(this.pa);2!=this.ic&&(N(3), O(17));this.Ia();this.I=2;this.Kb();};
e.Kb=function(){this.b.de()||this.Za||this.b.Tc(this);};e.Ia=function(){this.Fb();var a=this.ac;a&&"function"==typeof a.bb&&a.bb();this.ac=null;this.Vc.stop();this.Fc.pb();this.h&&(a=this.h, this.h=null, a.abort(), a.bb());};e.Hc=function(){return this.I};e.Yc=function(a){try{this.b.ue(this,a), N(4);}catch(b){this.a.cb(b,"Error in httprequest callback");}};function nc(a){if(a.H&&"function"==typeof a.H)return a.H();if(l(a))return a.split("");if(ca(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}return Ba(a)}
function oc(a,b,c){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,c);else if(ca(a)||l(a))na(a,b,c);else{if(a.W&&"function"==typeof a.W)var d=a.W();else if(a.H&&"function"==typeof a.H)d=void 0;else if(ca(a)||l(a)){d=[];for(var f=a.length,g=0;g<f;g++)d.push(g);}else d=Ca(a);f=nc(a);g=f.length;for(var k=0;k<g;k++)b.call(c,f[k],d&&d[k],a);}}function S(a,b){this.D={};this.o=[];this.j=0;var c=arguments.length;if(1<c){if(c%2)throw Error("Uneven number of arguments");for(var d=0;d<c;d+=2)this.set(arguments[d],arguments[d+1]);}else a&&this.addAll(a);}e=S.prototype;e.C=function(){return this.j};e.H=function(){this.wc();for(var a=[],b=0;b<this.o.length;b++)a.push(this.D[this.o[b]]);return a};e.W=function(){this.wc();return this.o.concat()};e.va=function(a){return T(this.D,a)};e.X=function(){return 0==this.j};
e.clear=function(){this.D={};this.j=this.o.length=0;};e.remove=function(a){return T(this.D,a)?(delete this.D[a], this.j--, this.o.length>2*this.j&&this.wc(), !0):!1};e.wc=function(){if(this.j!=this.o.length){for(var a=0,b=0;a<this.o.length;){var c=this.o[a];T(this.D,c)&&(this.o[b++]=c);a++;}this.o.length=b;}if(this.j!=this.o.length){var d={};for(b=a=0;a<this.o.length;)c=this.o[a], T(d,c)||(this.o[b++]=c, d[c]=1), a++;this.o.length=b;}};e.get=function(a,b){return T(this.D,a)?this.D[a]:b};
e.set=function(a,b){T(this.D,a)||(this.j++, this.o.push(a));this.D[a]=b;};e.addAll=function(a){if(a instanceof S)for(var b=a.W(),c=0;c<b.length;c++)this.set(b[c],a.get(b[c]));else for(b in a)this.set(b,a[b]);};e.forEach=function(a,b){for(var c=this.W(),d=0;d<c.length;d++){var f=c[d],g=this.get(f);a.call(b,g,f,this);}};e.clone=function(){return new S(this)};function T(a,b){return Object.prototype.hasOwnProperty.call(a,b)}var pc=/^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/;function qc(a,b){if(a){a=a.split("&");for(var c=0;c<a.length;c++){var d=a[c].indexOf("="),f=null;if(0<=d){var g=a[c].substring(0,d);f=a[c].substring(d+1);}else g=a[c];b(g,f?decodeURIComponent(f.replace(/\+/g," ")):"");}}}function U(a,b){this.xa=this.zb=this.qa="";this.Ca=null;this.ib=this.K="";this.O=this.Qf=!1;var c;a instanceof U?(this.O=void 0!==b?b:a.O, this.tb(a.qa), this.cd(a.zb), this.rb(a.xa), this.sb(a.Ca), this.ec(a.K), this.bd(a.P.clone()), this.$c(a.ib)):a&&(c=String(a).match(pc))?(this.O=!!b, this.tb(c[1]||"",!0), this.cd(c[2]||"",!0), this.rb(c[3]||"",!0), this.sb(c[4]), this.ec(c[5]||"",!0), this.bd(c[6]||"",!0), this.$c(c[7]||"",!0)):(this.O=!!b, this.P=new rc(null,this.O));}e=U.prototype;
e.toString=function(){var a=[],b=this.qa;b&&a.push(sc(b,tc,!0),":");var c=this.xa;if(c||"file"==b)a.push("//"), (b=this.zb)&&a.push(sc(b,tc,!0),"@"), a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g,"%$1")), c=this.Ca, null!=c&&a.push(":",String(c));if(c=this.K)this.Ic()&&"/"!=c.charAt(0)&&a.push("/"), a.push(sc(c,"/"==c.charAt(0)?uc:wc,!0));(c=this.Rd())&&a.push("?",c);(c=this.ib)&&a.push("#",sc(c,xc));return a.join("")};
e.resolve=function(a){var b=this.clone(),c=a.Hf();c?b.tb(a.qa):c=a.If();c?b.cd(a.zb):c=a.Ic();c?b.rb(a.xa):c=a.Ff();var d=a.K;if(c)b.sb(a.Ca);else if(c=a.ae()){if("/"!=d.charAt(0))if(this.Ic()&&!this.ae())d="/"+d;else{var f=b.K.lastIndexOf("/");-1!=f&&(d=b.K.substr(0,f+1)+d);}f=d;if(".."==f||"."==f)d="";else if(-1!=f.indexOf("./")||-1!=f.indexOf("/.")){d=0==f.lastIndexOf("/",0);f=f.split("/");for(var g=[],k=0;k<f.length;){var p=f[k++];"."==p?d&&k==f.length&&g.push(""):".."==p?((1<g.length||1==g.length&&
""!=g[0])&&g.pop(), d&&k==f.length&&g.push("")):(g.push(p), d=!0);}d=g.join("/");}else d=f;}c?b.ec(d):c=a.Gf();c?b.bd(a.P.clone()):c=a.Ef();c&&b.$c(a.ib);return b};e.clone=function(){return new U(this)};e.tb=function(a,b){this.U();if(this.qa=b?yc(a,!0):a)this.qa=this.qa.replace(/:$/,"");};e.Hf=function(){return!!this.qa};e.cd=function(a,b){this.U();this.zb=b?yc(a):a;};e.If=function(){return!!this.zb};e.rb=function(a,b){this.U();this.xa=b?yc(a,!0):a;};e.Ic=function(){return!!this.xa};
e.sb=function(a){this.U();if(a){a=Number(a);if(isNaN(a)||0>a)throw Error("Bad port number "+a);this.Ca=a;}else this.Ca=null;};e.Ff=function(){return null!=this.Ca};e.ec=function(a,b){this.U();this.K=b?yc(a,!0):a;};e.ae=function(){return!!this.K};e.Gf=function(){return""!==this.P.toString()};e.bd=function(a,b){this.U();a instanceof rc?(this.P=a, this.P.ad(this.O)):(b||(a=sc(a,zc)), this.P=new rc(a,this.O));};e.Rd=function(){return this.P.toString()};e.getQuery=function(){return this.Rd()};
e.l=function(a,b){this.U();this.P.set(a,b);};e.dc=function(a,b){this.U();n(b)||(b=[String(b)]);this.P.Ie(a,b);};e.$c=function(a,b){this.U();this.ib=b?yc(a):a;};e.Ef=function(){return!!this.ib};e.Ub=function(){this.U();this.l("zx",Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^w()).toString(36));return this};e.removeParameter=function(a){this.U();this.P.remove(a);return this};e.U=function(){if(this.Qf)throw Error("Tried to modify a read-only Uri");};
e.ad=function(a){this.O=a;this.P&&this.P.ad(a);};function Ac(a){return a instanceof U?a.clone():new U(a,void 0)}function Bc(a,b,c,d){var f=new U(null,void 0);a&&f.tb(a);b&&f.rb(b);c&&f.sb(c);d&&f.ec(d);return f}function yc(a,b){return a?b?decodeURI(a.replace(/%25/g,"%2525")):decodeURIComponent(a):""}function sc(a,b,c){return l(a)?(a=encodeURI(a).replace(b,Cc), c&&(a=a.replace(/%25([0-9a-fA-F]{2})/g,"%$1")), a):null}
function Cc(a){a=a.charCodeAt(0);return"%"+(a>>4&15).toString(16)+(a&15).toString(16)}var tc=/[#\/\?@]/g,wc=/[#\?:]/g,uc=/[#\?]/g,zc=/[#\?@]/g,xc=/#/g;function rc(a,b){this.j=this.m=null;this.ja=a||null;this.O=!!b;}e=rc.prototype;e.$=function(){if(!this.m&&(this.m=new S, this.j=0, this.ja)){var a=this;qc(this.ja,function(b,c){a.add(decodeURIComponent(b.replace(/\+/g," ")),c);});}};e.C=function(){this.$();return this.j};
e.add=function(a,b){this.$();this.Oa();a=this.Ma(a);var c=this.m.get(a);c||this.m.set(a,c=[]);c.push(b);this.j+=1;return this};e.remove=function(a){this.$();a=this.Ma(a);return this.m.va(a)?(this.Oa(), this.j-=this.m.get(a).length, this.m.remove(a)):!1};e.clear=function(){this.Oa();this.m=null;this.j=0;};e.X=function(){this.$();return 0==this.j};e.va=function(a){this.$();a=this.Ma(a);return this.m.va(a)};
e.forEach=function(a,b){this.$();this.m.forEach(function(c,d){na(c,function(c){a.call(b,c,d,this);},this);},this);};e.W=function(){this.$();for(var a=this.m.H(),b=this.m.W(),c=[],d=0;d<b.length;d++)for(var f=a[d],g=0;g<f.length;g++)c.push(b[d]);return c};e.H=function(a){this.$();var b=[];if(l(a))this.va(a)&&(b=ta(b,this.m.get(this.Ma(a))));else{a=this.m.H();for(var c=0;c<a.length;c++)b=ta(b,a[c]);}return b};
e.set=function(a,b){this.$();this.Oa();a=this.Ma(a);this.va(a)&&(this.j-=this.m.get(a).length);this.m.set(a,[b]);this.j+=1;return this};e.get=function(a,b){if(!a)return b;a=this.H(a);return 0<a.length?String(a[0]):b};e.Ie=function(a,b){this.remove(a);0<b.length&&(this.Oa(), this.m.set(this.Ma(a),ua(b)), this.j+=b.length);};
e.toString=function(){if(this.ja)return this.ja;if(!this.m)return"";for(var a=[],b=this.m.W(),c=0;c<b.length;c++){var d=b[c],f=encodeURIComponent(String(d));d=this.H(d);for(var g=0;g<d.length;g++){var k=f;""!==d[g]&&(k+="="+encodeURIComponent(String(d[g])));a.push(k);}}return this.ja=a.join("&")};e.Oa=function(){this.ja=null;};e.clone=function(){var a=new rc;a.ja=this.ja;this.m&&(a.m=this.m.clone(), a.j=this.j);return a};e.Ma=function(a){a=String(a);this.O&&(a=a.toLowerCase());return a};
e.ad=function(a){a&&!this.O&&(this.$(), this.Oa(), this.m.forEach(function(a,c){var b=c.toLowerCase();c!=b&&(this.remove(c), this.Ie(b,a));},this));this.O=a;};e.extend=function(a){for(var b=0;b<arguments.length;b++)oc(arguments[b],function(a,b){this.add(b,a);},this);};function Fc(){}function Gc(){}x(Gc,Fc);function Hc(a,b){this.b=a;this.a=b;this.f=this.A=null;this.bc=!1;this.K=null;this.w=-1;this.Ad=this.na=null;}e=Hc.prototype;e.g=null;e.ga=function(a){this.A=a;};e.connect=function(a){this.K=a;a=this.b.Sd(this.K);O(3);var b=this.b.Ib.$d;null!=b?(this.na=this.b.$a(b[0]), this.g=1, this.xd()):(a.dc("MODE","init"), !this.b.ta&&this.b.aa&&a.dc("X-HTTP-Session-Id",this.b.aa), this.f=new R(this,this.a,void 0,void 0,void 0), this.f.ga(this.A), this.f.jd(a,!1,null), this.g=0);};
e.xd=function(){this.a.debug("TestConnection: starting stage 2");var a=this.b.Ib.od;if(null!=a)this.a.debug(function(){return"TestConnection: skipping stage 2, precomputed result is "+a?"Buffered":"Unbuffered"}), O(4), a?(O(10), this.b.ub(this,!1)):(O(11), this.b.ub(this,!0));else{this.f=new R(this,this.a,void 0,void 0,void 0);this.f.ga(this.A);var b=this.b.Pd(this.na,this.K);O(4);b.dc("TYPE","xmlhttp");var c=this.b.aa,d=this.b.Kc;c&&d&&b.l(c,d);this.f.jd(b,!1,this.na);}};e.Jb=function(a){return this.b.Jb(a)};
e.abort=function(){this.f&&(this.f.cancel(), this.f=null);this.w=-1;};e.de=function(){return!1};
e.ue=function(a,b){this.w=a.w;if(0==this.g)if(this.a.debug("TestConnection: Got data for stage 1"), this.pc(a), b){try{var c=this.b.kc.zc(b);}catch(d){this.a.cb(d);this.b.dd(this);return}this.na=this.b.$a(c[0]);}else this.a.debug("TestConnection: Null responseText"), this.b.dd(this);else 1==this.g&&(this.bc?O(6):"11111"==b?(O(5), this.bc=!0, this.Ze()&&(this.w=200, this.f.cancel(), this.a.debug("Test connection succeeded; using streaming connection"), O(11), this.b.ub(this,!0))):(O(7), this.bc=!1));};
e.Tc=function(){this.w=this.f.w;this.f.S?0==this.g?(this.g=1, this.a.debug("TestConnection: request complete for initial check"), this.xd()):1==this.g&&(this.a.debug("TestConnection: request complete for stage 2"), this.bc?(this.a.debug("Test connection succeeded; using streaming connection"), O(11), this.b.ub(this,!0)):(this.a.debug("Test connection failed; not using streaming"), O(10), this.b.ub(this,!1))):(this.a.debug("TestConnection: request failed, in state "+this.g), 0==this.g?O(8):1==this.g&&O(9), this.b.dd(this));};e.pc=function(a){if(!this.b.ta&&(a=a.h)){var b=a.kb("X-Client-Wire-Protocol");this.Ad=b?b:null;this.b.aa&&((a=a.kb("X-HTTP-Session-Id"))?this.b.Fe(a):this.a.T("Missing X_HTTP_SESSION_ID in the handshake response"));}};e.fc=function(){return this.b.fc()};e.Ba=function(){return this.b.Ba()};e.Ze=function(){return!C||10<=Number(Ua)};function Ic(){this.od=this.$d=null;}function Jc(a){this.D=new S;a&&this.addAll(a);}function Kc(a){var b=typeof a;return"object"==b&&a||"function"==b?"o"+(a[t]||(a[t]=++da)):b.substr(0,1)+a}e=Jc.prototype;e.C=function(){return this.D.C()};e.add=function(a){this.D.set(Kc(a),a);};e.addAll=function(a){a=nc(a);for(var b=a.length,c=0;c<b;c++)this.add(a[c]);};e.pb=function(a){a=nc(a);for(var b=a.length,c=0;c<b;c++)this.remove(a[c]);};e.remove=function(a){return this.D.remove(Kc(a))};e.clear=function(){this.D.clear();};e.X=function(){return this.D.X()};
e.contains=function(a){return this.D.va(Kc(a))};e.H=function(){return this.D.H()};e.clone=function(){return new Jc(this)};function Lc(a,b){this.Pc=a;this.map=b;this.context=null;}function Mc(a){this.me=a||Nc;h.PerformanceNavigationTiming?(a=h.performance.getEntriesByType("navigation"), a=0<a.length&&("hq"==a[0].nextHopProtocol||"h2"==a[0].nextHopProtocol)):a=!!(h.vc&&h.vc.ke&&h.vc.ke()&&h.vc.ke().nh);this.Xb=a?this.me:1;this.v=null;1<this.Xb&&(this.v=new Jc);this.f=null;this.ba=[];}var Nc=10;e=Mc.prototype;e.ld=function(a){this.v||-1==a.indexOf("spdy")&&-1==a.indexOf("quic")&&-1==a.indexOf("h2")||(this.Xb=this.me, this.v=new Jc, this.f&&(this.oc(this.f), this.f=null));};
e.ee=function(){return this.f?!0:this.v?this.v.C()>=this.Xb:!1};e.xf=function(){return this.f?1:this.v?this.v.C():0};e.Jc=function(a){return this.f?this.f==a:this.v?this.v.contains(a):!1};e.oc=function(a){this.v?this.v.add(a):this.f=a;};e.ze=function(a){this.f&&this.f==a?this.f=null:this.v&&this.v.contains(a)&&this.v.remove(a);};e.cancel=function(){this.ba=this.la();this.f?(this.f.cancel(), this.f=null):this.v&&!this.v.X()&&(na(this.v.H(),function(a){a.cancel();}), this.v.clear());};
e.la=function(){if(null!=this.f)return this.ba.concat(this.f.la());if(null!=this.v&&!this.v.X()){var a=this.ba;na(this.v.H(),function(b){a=a.concat(b.la());});return a}return ua(this.ba)};e.Re=function(a){this.ba=this.ba.concat(a);};e.$e=function(){this.ba.length=0;};function Oc(){this.xg=this.rg=void 0;}Oc.prototype.stringify=function(a){return h.JSON.stringify(a,this.rg)};Oc.prototype.parse=function(a){return h.JSON.parse(a,this.xg)};function Pc(){this.jg=new Oc;}Pc.prototype.hf=function(a,b,c){var d=c||"";try{oc(a,function(a,c){var f=a;r(a)&&(f=ub(a));b.push(d+c+"="+encodeURIComponent(f));});}catch(f){throw b.push(d+"type="+encodeURIComponent("_badmap")), f;}};Pc.prototype.jf=function(a,b,c){for(var d=-1;;){var f=["count="+b];-1==d?0<b?(d=a[0].Pc, f.push("ofs="+d)):d=0:f.push("ofs="+d);for(var g=!0,k=0;k<b;k++){var p=a[k].Pc,Ub=a[k].map;p-=d;if(0>p)d=Math.max(0,a[k].Pc-100), g=!1;else try{this.hf(Ub,f,"req"+p+"_");}catch(md){c&&c(Ub);}}if(g)return f.join("&")}};
Pc.prototype.zc=function(a){return this.jg.parse(a)};function Qc(a,b){var c=new Xb;c.debug("TestLoadImage: loading "+a);var d=new Image;d.onload=v(Rc,c,d,"TestLoadImage: loaded",!0,b);d.onerror=v(Rc,c,d,"TestLoadImage: error",!1,b);d.onabort=v(Rc,c,d,"TestLoadImage: abort",!1,b);d.ontimeout=v(Rc,c,d,"TestLoadImage: timeout",!1,b);h.setTimeout(function(){if(d.ontimeout)d.ontimeout();},1E4);d.src=a;}function Rc(a,b,c,d,f){try{a.debug(c), b.onload=null, b.onerror=null, b.onabort=null, b.ontimeout=null, f(d);}catch(g){a.cb(g);}}var Sc=h.JSON.parse;function V(a){H.call(this);this.headers=new S;this.Xa=a||null;this.ha=!1;this.mc=this.c=null;this.ge=this.Tb="";this.Pa=0;this.I="";this.Aa=this.Lc=this.Qb=this.Ec=!1;this.vb=0;this.hc=null;this.Ae=Tc;this.jc=this.lg=this.Ab=!1;}x(V,H);var Tc="";V.prototype.s=Vb("goog.net.XhrIo");var Uc=/^https?$/i,Vc=["POST","PUT"];e=V.prototype;e.Je=function(a){this.Ab=a;};
e.send=function(a,b,c,d){if(this.c)throw Error("[goog.net.XhrIo] Object is active with another request="+this.Tb+"; newUri="+a);b=b?b.toUpperCase():"GET";this.Tb=a;this.I="";this.Pa=0;this.ge=b;this.Ec=!1;this.ha=!0;this.c=this.df();this.mc=this.Xa?this.Xa.Vd():hc.Vd();this.c.onreadystatechange=u(this.te,this);this.lg&&"onprogress"in this.c&&(this.c.onprogress=u(function(a){this.re(a,!0);},this), this.c.upload&&(this.c.upload.onprogress=u(this.re,this)));try{L(this.s,this.da("Opening Xhr")), this.Lc=
!0, this.c.open(b,String(a),!0), this.Lc=!1;}catch(g){L(this.s,this.da("Error opening Xhr: "+g.message));this.Ld(g);return}a=c||"";var f=this.headers.clone();d&&oc(d,function(a,b){f.set(b,a);});d=pa(f.W());c=h.FormData&&a instanceof h.FormData;!(0<=la(Vc,b))||d||c||f.set("Content-Type","application/x-www-form-urlencoded;charset=utf-8");f.forEach(function(a,b){this.c.setRequestHeader(b,a);},this);this.Ae&&(this.c.responseType=this.Ae);"withCredentials"in this.c&&this.c.withCredentials!==this.Ab&&(this.c.withCredentials=
this.Ab);try{this.yd(), 0<this.vb&&(this.jc=Wc(this.c), L(this.s,this.da("Will abort after "+this.vb+"ms if incomplete, xhr2 "+this.jc)), this.jc?(this.c.timeout=this.vb, this.c.ontimeout=u(this.Ua,this)):this.hc=Hb(this.Ua,this.vb,this)), L(this.s,this.da("Sending request")), this.Qb=!0, this.c.send(a), this.Qb=!1;}catch(g){L(this.s,this.da("Send error: "+g.message)), this.Ld(g);}};function Wc(a){return C&&Ta(9)&&"number"==typeof a.timeout&&void 0!==a.ontimeout}
function qa(a){return"content-type"==a.toLowerCase()}e.df=function(){return this.Xa?this.Xa.Dd():hc.Dd()};e.Ua=function(){"undefined"!=typeof goog&&this.c&&(this.I="Timed out after "+this.vb+"ms, aborting", this.Pa=8, L(this.s,this.da(this.I)), this.dispatchEvent("timeout"), this.abort(8));};e.Ld=function(a){this.ha=!1;this.c&&(this.Aa=!0, this.c.abort(), this.Aa=!1);this.I=a;this.Pa=5;this.Jd();this.Gb();};e.Jd=function(){this.Ec||(this.Ec=!0, this.dispatchEvent("complete"), this.dispatchEvent("error"));};
e.abort=function(a){this.c&&this.ha&&(L(this.s,this.da("Aborting")), this.ha=!1, this.Aa=!0, this.c.abort(), this.Aa=!1, this.Pa=a||7, this.dispatchEvent("complete"), this.dispatchEvent("abort"), this.Gb());};e.F=function(){this.c&&(this.ha&&(this.ha=!1, this.Aa=!0, this.c.abort(), this.Aa=!1), this.Gb(!0));V.L.F.call(this);};e.te=function(){this.Ka||(this.Lc||this.Qb||this.Aa?this.se():this.eg());};e.eg=function(){this.se();};
e.se=function(){if(this.ha&&"undefined"!=typeof goog)if(this.mc[1]&&4==this.ma()&&2==this.za())L(this.s,this.da("Local request error detected and ignored"));else if(this.Qb&&4==this.ma())Hb(this.te,0,this);else if(this.dispatchEvent("readystatechange"), this.Mc()){L(this.s,this.da("Request complete"));this.ha=!1;try{this.Rf()?(this.dispatchEvent("complete"), this.dispatchEvent("success")):(this.Pa=6, this.I=this.Yd()+" ["+this.za()+"]", this.Jd());}finally{this.Gb();}}};
e.re=function(a,b){this.dispatchEvent(Xc(a,"progress"));this.dispatchEvent(Xc(a,b?"downloadprogress":"uploadprogress"));};function Xc(a,b){return{type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}}e.Gb=function(a){if(this.c){this.yd();var b=this.c,c=this.mc[0]?aa:null;this.mc=this.c=null;a||this.dispatchEvent("ready");try{b.onreadystatechange=c;}catch(d){(a=this.s)&&a.ca("Problem encountered resetting onreadystatechange: "+d.message,void 0);}}};
e.yd=function(){this.c&&this.jc&&(this.c.ontimeout=null);this.hc&&(h.clearTimeout(this.hc), this.hc=null);};e.Ba=function(){return!!this.c};e.Mc=function(){return 4==this.ma()};e.Rf=function(){var a=this.za();a:switch(a){case 200:case 201:case 202:case 204:case 206:case 304:case 1223:var b=!0;break a;default:b=!1;}return b||0===a&&!this.Of()};
e.Of=function(){var a=String(this.Tb).match(pc)[1]||null;!a&&h.self&&h.self.location&&(a=h.self.location.protocol, a=a.substr(0,a.length-1));return Uc.test(a?a.toLowerCase():"")};e.ma=function(){return this.c?this.c.readyState:0};e.za=function(){try{return 2<this.ma()?this.c.status:-1}catch(a){return-1}};e.Yd=function(){try{return 2<this.ma()?this.c.statusText:""}catch(a){return L(this.s,"Can not get status: "+a.message), ""}};
e.ya=function(){try{return this.c?this.c.responseText:""}catch(a){return L(this.s,"Can not get responseText: "+a.message), ""}};e.yf=function(a){if(this.c){var b=this.c.responseText;a&&0==b.indexOf(a)&&(b=b.substring(a.length));return Sc(b)}};e.getResponseHeader=function(a){if(this.c&&this.Mc())return a=this.c.getResponseHeader(a), null===a?void 0:a};e.getAllResponseHeaders=function(){return this.c&&this.Mc()?this.c.getAllResponseHeaders()||"":""};
e.kb=function(a){return this.c?this.c.getResponseHeader(a):null};e.Ud=function(){return this.Pa};e.Hc=function(){return l(this.I)?this.I:String(this.I)};e.da=function(a){return a+" ["+this.ge+" "+this.Tb+" "+this.za()+"]"};function Yc(a){var b="";Aa(a,function(a,d){b+=d;b+=":";b+=a;b+="\r\n";});return b}function Zc(a,b,c){a:{for(d in c){var d=!1;break a}d=!0;}if(d)return a;c=Yc(c);if(l(a)){b=encodeURIComponent(String(b));c=null!=c?"="+encodeURIComponent(String(c)):"";if(b+=c){c=a.indexOf("#");0>c&&(c=a.length);d=a.indexOf("?");if(0>d||d>c){d=c;var f="";}else f=a.substring(d+1,c);a=[a.substr(0,d),f,a.substr(c)];c=a[1];a[1]=b?c?c+"&"+b:b:c;a=a[0]+(a[1]?"?"+a[1]:"")+a[2];}return a}a.l(b,c);return a}function $c(a){this.Bd=22;this.De=0;this.M=[];this.a=new Xb;this.Ib=new Ic;this.na=this.md=this.hb=this.K=this.u=this.Kc=this.aa=this.gb=this.N=this.Rb=this.A=null;this.Te=!0;this.ag=this.Yb=0;this.kf=!!m("internalChannelParams.failFast",a);this.fd=this.Ja=this.wa=this.ia=this.ea=this.i=null;this.Se=!0;this.w=this.he=this.Sb=-1;this.rc=this.Ha=this.La=0;this.Ve=m("internalChannelParams.baseRetryDelayMs",a)||5E3;this.vg=m("internalChannelParams.retryDelaySeedMs",a)||1E4;this.nf=m("internalChannelParams.forwardChannelMaxRetries",
a)||2;this.Od=m("internalChannelParams.forwardChannelRequestTimeoutMs",a)||2E4;this.Xa=a&&a.oh||void 0;this.Db=void 0;this.Ra=0;this.gc=a&&a.supportsCrossDomainXhr||!1;this.ra="";this.G=new Mc(a&&a.concurrentRequestLimit);this.kc=new Pc;this.ta=a&&void 0!==a.backgroundChannelTest?a.backgroundChannelTest:!0;(this.Nd=a&&a.fastHandshake||!1)&&!this.ta&&(this.a.T("Force backgroundChannelTest when fastHandshake is enabled."), this.ta=!0);a&&a.Id&&this.a.Id();}e=$c.prototype;e.tc=8;e.g=1;
e.connect=function(a,b,c,d,f){this.a.debug("connect()");O(0);this.K=b;this.gb=c||{};d&&void 0!==f&&(this.gb.OSID=d, this.gb.OAID=f);this.ta&&(this.a.debug("connect() bypassed channel-test."), this.Ib.$d=[], this.Ib.od=!1);this.bf(a);};e.disconnect=function(){this.a.debug("disconnect()");this.qd();if(3==this.g){var a=this.Yb++,b=this.hb.clone();b.l("SID",this.ra);b.l("RID",a);b.l("TYPE","terminate");this.Ya(b);(new R(this,this.a,this.ra,a,void 0)).yg(b);}this.qe();};
e.bf=function(a){this.a.debug("connectTest_()");this.Ja=new Hc(this,this.a);null===this.N&&this.Ja.ga(this.A);var b=a;this.N&&this.A&&(b=Zc(a,this.N,this.A));this.Ja.connect(b);};e.af=function(){this.a.debug("connectChannel_()");this.hb=this.Sd(this.K);this.Dc();};e.qd=function(){this.Ja&&(this.Ja.abort(), this.Ja=null);this.u&&(this.u.cancel(), this.u=null);this.ia&&(h.clearTimeout(this.ia), this.ia=null);this.Hb();this.G.cancel();this.ea&&(h.clearTimeout(this.ea), this.ea=null);};
e.ga=function(a){this.A=a;};e.Eg=function(a){this.Rb=a;};e.Cg=function(a){this.N=a;};e.Dg=function(a){this.aa=a;};e.Fe=function(a){this.Kc=a;};e.He=function(a){this.Ra=a;};e.Hg=function(){this.gc=!0;};e.Ee=function(a){this.i=a;};e.Nf=function(){return!this.fd};e.Zc=function(a){1E3==this.M.length&&this.a.ca(function(){return"Already have 1000 queued maps upon queueing "+ub(a)});this.M.push(new Lc(this.ag++,a));3==this.g&&this.Dc();};e.qf=function(){return this.kf?0:this.nf};e.de=function(){return 0==this.g};
e.getState=function(){return this.g};e.Dc=function(){this.G.ee()||this.ea||(this.ea=P(u(this.we,this),0), this.La=0);};e.Yf=function(a){if(this.G.xf()>=this.G.Xb-(this.ea?1:0))return this.a.ca("Unexpected retry request is scheduled."), !1;if(this.ea)return this.a.debug("Use the retry request that is already scheduled."), this.M=a.la().concat(this.M), !0;if(1==this.g||2==this.g||this.La>=this.qf())return!1;this.a.debug("Going to retry POST");this.ea=P(u(this.we,this,a),this.Xd(this.La));this.La++;return!0};
e.we=function(a){this.ea=null;this.Mg(a);};e.Mg=function(a){this.a.debug("startForwardChannel_");1==this.g?a?this.a.ca("Not supposed to retry the open"):(this.ig(), this.g=2):3==this.g&&(a?this.le(a):0==this.M.length?this.a.debug("startForwardChannel_ returned: nothing to send"):this.G.ee()?this.a.ca("startForwardChannel_ returned: connection already in progress"):(this.le(), this.a.debug("startForwardChannel_ finished, sent request")));};
e.ig=function(){this.a.debug("open_()");this.Yb=Math.floor(1E5*Math.random());var a=this.Yb++,b=new R(this,this.a,"",a,void 0),c=this.A;this.Rb&&(c?(c=Da(c), Fa(c,this.Rb)):c=this.Rb);null===this.N&&b.ga(c);var d=this.Hd(b),f=this.hb.clone();f.l("RID",a);0<this.Bd&&f.l("CVER",this.Bd);this.ta&&this.aa&&f.l("X-HTTP-Session-Id",this.aa);this.Ya(f);this.N&&c&&Zc(f,this.N,c);this.G.oc(b);this.Nd?(f.l("$req",d), f.l("SID","null"), b.Ag(), b.kd(f,null)):b.kd(f,d);};
e.le=function(a){var b=a?a.R:this.Yb++;var c=this.hb.clone();c.l("SID",this.ra);c.l("RID",b);c.l("AID",this.Sb);this.Ya(c);this.N&&this.A&&Zc(c,this.N,this.A);b=new R(this,this.a,this.ra,b,this.La+1);null===this.N&&b.ga(this.A);a&&this.sg(a);a=this.Hd(b);b.setTimeout(Math.round(.5*this.Od)+Math.round(.5*this.Od*Math.random()));this.G.oc(b);b.kd(c,a);};e.Ya=function(a){this.i&&oc({},function(b,c){a.l(c,b);});};
e.Hd=function(a){var b=Math.min(this.M.length,1E3),c=this.i?u(this.i.Ue,this.i,this):null;c=this.kc.jf(this.M,b,c);a.Gg(this.M.splice(0,b));return c};e.sg=function(a){this.M=a.la().concat(this.M);};e.Kd=function(){if(!this.u&&!this.ia){this.rc=1;var a=this.ve;Bb||Cb();Eb||(Bb(), Eb=!0);Fb.add(a,this);this.Ha=0;}};
e.Qc=function(){if(this.u||this.ia)return this.a.ca("Request already in progress"), !1;if(3<=this.Ha)return!1;this.a.debug("Going to retry GET");this.rc++;this.ia=P(u(this.ve,this),this.Xd(this.Ha));this.Ha++;return!0};e.ve=function(){this.ia=null;this.Kg();};
e.Kg=function(){this.a.debug("Creating new HttpRequest");this.u=new R(this,this.a,this.ra,"rpc",this.rc);null===this.N&&this.u.ga(this.A);this.u.He(this.Ra);var a=this.md.clone();a.l("RID","rpc");a.l("SID",this.ra);a.l("CI",this.fd?"0":"1");a.l("AID",this.Sb);this.Ya(a);a.l("TYPE","xmlhttp");this.N&&this.A&&Zc(a,this.N,this.A);this.Db&&this.u.setTimeout(this.Db);this.u.jd(a,!0,this.na);this.a.debug("New Request created");};
e.ub=function(a,b){this.a.debug("Test Connection Finished");var c=a.Ad;c&&this.G.ld(c);this.fd=this.Se&&b;this.w=a.w;this.af();};e.dd=function(a){this.a.debug("Test Connection Failed");this.w=a.w;this.sa(2);};
e.ue=function(a,b){if(0!=this.g&&(this.u==a||this.G.Jc(a)))if(this.w=a.w, !a.lb&&this.G.Jc(a)&&3==this.g){try{var c=this.kc.zc(b);}catch(d){c=null;}n(c)&&3==c.length?this.Cf(c,a):(this.a.debug("Bad POST response data returned"), this.sa(11));}else(a.lb||this.u==a)&&this.Hb(), va(b)||(c=this.kc.zc(b), this.dg(c,a));};
e.Cf=function(a,b){0==a[0]?this.Bf(b):(this.he=a[1], b=this.he-this.Sb, 0<b&&(a=a[2], this.a.debug(a+" bytes (in "+b+" arrays) are outstanding on the BackChannel"), this.Jg(a)&&!this.wa&&(this.wa=P(u(this.bg,this),6E3))));};
e.Bf=function(a){this.a.debug("Server claims our backchannel is missing.");if(this.ia)this.a.debug("But we are currently starting the request.");else{if(this.u)if(this.u.qb+3E3<a.qb)this.Hb(), this.u.cancel(), this.u=null;else return;else this.a.T("We do not have a BackChannel established");this.Qc();O(18);}};e.Jg=function(a){return 37500>a&&!this.Nf()&&0==this.Ha};e.$a=function(a){return this.Te?this.i?this.i.$a(a):a:null};
e.bg=function(){null!=this.wa&&(this.wa=null, this.u.cancel(), this.u=null, this.Qc(), O(19));};e.Hb=function(){null!=this.wa&&(h.clearTimeout(this.wa), this.wa=null);};
e.Tc=function(a){this.a.debug("Request complete");var b=null;if(this.u==a){this.Hb();this.u=null;var c=2;}else if(this.G.Jc(a))b=a.la(), this.G.ze(a), c=1;else return;this.w=a.w;if(0!=this.g)if(a.S)1==c?(ac(a.Da?a.Da.length:0,w()-a.qb,this.La), this.Dc()):this.Kd();else{var d=a.Hc();if(3==d||0==d&&0<this.w)this.a.debug("Not retrying due to error type");else{var f=this;this.a.debug(function(){return"Maybe retrying, last error: "+kc(d,f.w)});if(1==c&&this.Yf(a)||2==c&&this.Qc())return;this.a.debug("Exceeded max number of retries");}b&&
0<b.length&&this.G.Re(b);this.a.debug("Error: HTTP request failed");switch(d){case 1:this.sa(5);break;case 4:this.sa(10);break;case 3:this.sa(6);break;default:this.sa(2);}}};e.Xd=function(a){var b=this.Ve+Math.floor(Math.random()*this.vg);this.Ba()||(this.a.debug("Inactive channel"), b*=2);return b*a};e.pc=function(a){if(this.ta&&(a=a.h)){var b=a.kb("X-Client-Wire-Protocol");b&&this.G.ld(b);this.aa&&((a=a.kb("X-HTTP-Session-Id"))?(this.Fe(a), this.hb.l(this.aa,a)):this.a.T("Missing X_HTTP_SESSION_ID in the handshake response"));}};
e.dg=function(a,b){for(var c=this.i&&this.i.sc?[]:null,d=0;d<a.length;d++){var f=a[d];this.Sb=f[0];f=f[1];if(2==this.g)if("c"==f[0]){this.ra=f[1];this.na=this.$a(f[2]);var g=f[3];null!=g&&(this.tc=g, this.a.info("VER="+this.tc));g=f[4];null!=g&&(this.De=g, this.a.info("SVER="+this.De));f=f[5];null!=f&&"number"==typeof f&&0<f&&(this.Db=f*=1.5, this.a.info("backChannelRequestTimeoutMs_="+f));this.pc(b);this.g=3;this.i&&this.i.wd();this.Lg(b);}else"stop"!=f[0]&&"close"!=f[0]||this.sa(7);else 3==this.g&&
("stop"==f[0]||"close"==f[0]?(c&&0!=c.length&&(this.i.sc(this,c), c.length=0), "stop"==f[0]?this.sa(7):this.disconnect()):"noop"!=f[0]&&(c?c.push(f):this.i&&this.i.ud(f)), this.Ha=0);}c&&0!=c.length&&this.i.sc(this,c);};e.Lg=function(a){this.md=this.Pd(this.na,this.K);a.lb?(this.a.debug("Upgrade the handshake request to a backchannel."), this.G.ze(a), a.tg(this.Db), this.u=a):this.Kd();};
e.sa=function(a){this.a.info("Error code "+a);if(2==a){var b=null;this.i&&(b=null);var c=u(this.Pg,this);b||(b=new U("//www.google.com/images/cleardot.gif"), h.location&&"http"==h.location.protocol||b.tb("https"), b.Ub());Qc(b.toString(),c);}else O(2);this.cg(a);};e.Pg=function(a){a?(this.a.info("Successfully pinged google.com"), O(2)):(this.a.info("Failed to ping google.com"), O(1));};e.cg=function(a){this.a.debug("HttpChannel: error - "+a);this.g=0;this.i&&this.i.td(a);this.qe();this.qd();};
e.qe=function(){this.g=0;this.w=-1;if(this.i){var a=this.G.la();if(0!=a.length||0!=this.M.length){var b=this;this.a.debug(function(){return"Number of undelivered maps, pending: "+a.length+", outgoing: "+b.M.length});this.G.$e();ua(this.M);this.M.length=0;}this.i.sd();}};e.Sd=function(a){a=this.Cd(null,a);this.a.debug("GetForwardChannelUri: "+a);return a};e.Pd=function(a,b){a=this.Cd(this.fc()?a:null,b);this.a.debug("GetBackChannelUri: "+a);return a};
e.Cd=function(a,b){var c=Ac(b);if(""!=c.xa)a&&c.rb(a+"."+c.xa), c.sb(c.Ca);else{var d=h.location;c=Bc(d.protocol,a?a+"."+d.hostname:d.hostname,+d.port,b);}this.gb&&Aa(this.gb,function(a,b){c.l(b,a);});a=this.aa;b=this.Kc;a&&b&&c.l(a,b);c.l("VER",this.tc);this.Ya(c);return c};e.Jb=function(a){if(a&&!this.gc)throw Error("Can't create secondary domain capable XhrIo object.");a=new V(this.Xa);a.Je(this.gc);return a};e.Ba=function(){return!!this.i&&this.i.Ba()};e.fc=function(){return this.gc};function ad(){}e=ad.prototype;e.sc=null;e.wd=function(){};e.ud=function(){};e.td=function(){};e.sd=function(){};e.Ba=function(){return!0};e.Ue=function(){};e.$a=function(a){return a};function bd(a){for(var b=arguments[0],c=1;c<arguments.length;c++){var d=arguments[c];if(0==d.lastIndexOf("/",0))b=d;else{var f;(f=""==b)||(f=b.length-1, f=0<=f&&b.indexOf("/",f)==f);b=f?b+d:b+("/"+d);}}return b}function cd(){if(C&&!(10<=Number(Ua)))throw Error("Environmental error: no available transport.");}cd.prototype.cf=function(a,b){return new W(a,b)};
function W(a,b){H.call(this);this.b=new $c(b);this.yb=a;this.Qg=b&&b.testUrl?b.testUrl:bd(this.yb,"test");this.s=Vb("goog.labs.net.webChannel.WebChannelBaseTransport");this.Rc=b&&b.messageUrlParams||null;a=b&&b.messageHeaders||null;b&&b.clientProtocolHeaderRequired&&(a?a["X-Client-Protocol"]="webchannel":a={"X-Client-Protocol":"webchannel"});this.b.ga(a);a=b&&b.initMessageHeaders||null;b&&b.messageContentType&&(a?a["X-WebChannel-Content-Type"]=b.messageContentType:a={"X-WebChannel-Content-Type":b.messageContentType});
b&&b.zd&&(a?a["X-WebChannel-Client-Profile"]=b.zd:a={"X-WebChannel-Client-Profile":b.zd});this.b.Eg(a);(a=b&&b.httpHeadersOverwriteParam)&&!va(a)&&this.b.Cg(a);this.Og=b&&b.supportsCrossDomainXhr||!1;this.zg=b&&b.sendRawJson||!1;(b=b&&b.httpSessionIdParam)&&!va(b)&&(this.b.Dg(b), a=this.Rc, null!==a&&b in a&&(a=this.Rc, b in a&&delete a[b], (a=this.s)&&a.T("Ignore httpSessionIdParam also specified with messageUrlParams: "+b,void 0)));this.vd=new X(this);}x(W,H);e=W.prototype;
e.addEventListener=function(a,b,c,d){W.L.addEventListener.call(this,a,b,c,d);};e.removeEventListener=function(a,b,c,d){W.L.removeEventListener.call(this,a,b,c,d);};e.open=function(){this.b.Ee(this.vd);this.Og&&this.b.Hg();this.b.connect(this.Qg,this.yb,this.Rc||void 0);};e.close=function(){this.b.disconnect();};e.send=function(a){if(l(a)){var b={};b.__data__=a;this.b.Zc(b);}else this.zg?(b={}, b.__data__=ub(a), this.b.Zc(b)):this.b.Zc(a);};
e.F=function(){this.b.Ee(null);delete this.vd;this.b.disconnect();delete this.b;W.L.F.call(this);};function dd(a){fc.call(this);var b=a.__sm__;if(b){a:{for(var c in b){a=c;break a}a=void 0;}if(this.ne=a)a=this.ne, b=null!==b&&a in b?b[a]:void 0;this.data=b;}else this.data=a;}x(dd,fc);function ed(a){gc.call(this);this.status=1;this.errorCode=a;}x(ed,gc);function X(a){this.b=a;}x(X,ad);X.prototype.wd=function(){Wb(this.b.s,"WebChannel opened on "+this.b.yb);this.b.dispatchEvent("a");};X.prototype.ud=function(a){this.b.dispatchEvent(new dd(a));};
X.prototype.td=function(a){Wb(this.b.s,"WebChannel aborted on "+this.b.yb+" due to channel error: "+a);this.b.dispatchEvent(new ed(a));};X.prototype.sd=function(){Wb(this.b.s,"WebChannel closed on "+this.b.yb);this.b.dispatchEvent("b");};var fd=v(function(a,b){function c(){}c.prototype=a.prototype;var d=new c;a.apply(d,Array.prototype.slice.call(arguments,1));return d},cd);function gd(){this.V=[];this.Z=[];}e=gd.prototype;e.Vf=function(){0==this.V.length&&(this.V=this.Z, this.V.reverse(), this.Z=[]);};e.enqueue=function(a){this.Z.push(a);};e.ab=function(){this.Vf();return this.V.pop()};e.C=function(){return this.V.length+this.Z.length};e.X=function(){return 0==this.V.length&&0==this.Z.length};e.clear=function(){this.V=[];this.Z=[];};e.contains=function(a){return 0<=la(this.V,a)||0<=la(this.Z,a)};
e.remove=function(a){var b=this.V;var c=ma(b,a);0<=c?(Array.prototype.splice.call(b,c,1), b=!0):b=!1;return b||sa(this.Z,a)};e.H=function(){for(var a=[],b=this.V.length-1;0<=b;--b)a.push(this.V[b]);var c=this.Z.length;for(b=0;b<c;++b)a.push(this.Z[b]);return a};function hd(a,b){z.call(this);this.oe=a||0;this.Wb=b||10;if(this.oe>this.Wb)throw Error(id);this.fa=new gd;this.oa=new Jc;this.Ac=0;this.Nc=null;this.Cb();}x(hd,z);var id="[goog.structs.Pool] Min can not be greater than max";e=hd.prototype;e.Mb=function(){var a=w();if(!(null!=this.Nc&&a-this.Nc<this.Ac)){var b=this.qg();b&&(this.Nc=a, this.oa.add(b));return b}};e.og=function(a){return this.oa.remove(a)?(this.nc(a), !0):!1};
e.qg=function(){for(var a;0<this.Td()&&(a=this.fa.ab(), !this.Sc(a));)this.Cb();!a&&this.C()<this.Wb&&(a=this.xc());return a};e.nc=function(a){this.oa.remove(a);this.Sc(a)&&this.C()<this.Wb?this.fa.enqueue(a):this.Bc(a);};e.Cb=function(){for(var a=this.fa;this.C()<this.oe;)a.enqueue(this.xc());for(;this.C()>this.Wb&&0<this.Td();)this.Bc(a.ab());};e.xc=function(){return{}};e.Bc=function(a){if("function"==typeof a.bb)a.bb();else for(var b in a)a[b]=null;};
e.Sc=function(a){return"function"==typeof a.Ye?a.Ye():!0};e.contains=function(a){return this.fa.contains(a)||this.oa.contains(a)};e.C=function(){return this.fa.C()+this.oa.C()};e.rf=function(){return this.oa.C()};e.Td=function(){return this.fa.C()};e.X=function(){return this.fa.X()&&this.oa.X()};e.F=function(){hd.L.F.call(this);if(0<this.rf())throw Error("[goog.structs.Pool] Objects not released");delete this.oa;for(var a=this.fa;!a.X();)this.Bc(a.ab());delete this.fa;};function jd(a,b){this.fe=a;this.gd=b;}jd.prototype.getKey=function(){return this.fe};jd.prototype.clone=function(){return new jd(this.fe,this.gd)};function kd(a){this.Y=[];a&&this.Lf(a);}e=kd.prototype;e.ce=function(a,b){var c=this.Y;c.push(new jd(a,b));this.$f(c.length-1);};e.Lf=function(a){if(a instanceof kd){var b=a.W();a=a.H();if(0>=this.C()){for(var c=this.Y,d=0;d<b.length;d++)c.push(new jd(b[d],a[d]));return}}else b=Ca(a), a=Ba(a);for(d=0;d<b.length;d++)this.ce(b[d],a[d]);};e.remove=function(){var a=this.Y,b=a.length,c=a[0];if(!(0>=b))return 1==b?ra(a):(a[0]=a.pop(), this.Zf()), c.gd};
e.Zf=function(){for(var a=0,b=this.Y,c=b.length,d=b[a];a<c>>1;){var f=this.tf(a),g=this.zf(a);f=g<c&&b[g].getKey()<b[f].getKey()?g:f;if(b[f].getKey()>d.getKey())break;b[a]=b[f];a=f;}b[a]=d;};e.$f=function(a){for(var b=this.Y,c=b[a];0<a;){var d=this.wf(a);if(b[d].getKey()>c.getKey())b[a]=b[d], a=d;else break}b[a]=c;};e.tf=function(a){return 2*a+1};e.zf=function(a){return 2*a+2};e.wf=function(a){return a-1>>1};e.H=function(){for(var a=this.Y,b=[],c=a.length,d=0;d<c;d++)b.push(a[d].gd);return b};
e.W=function(){for(var a=this.Y,b=[],c=a.length,d=0;d<c;d++)b.push(a[d].getKey());return b};e.va=function(a){return oa(this.Y,function(b){return b.getKey()==a})};e.clone=function(){return new kd(this)};e.C=function(){return this.Y.length};e.X=function(){return 0==this.Y.length};e.clear=function(){ra(this.Y);};function ld(){kd.call(this);}x(ld,kd);ld.prototype.enqueue=function(a,b){this.ce(a,b);};ld.prototype.ab=function(){return this.remove()};function Y(a,b){this.Gd=void 0;this.cc=new ld;hd.call(this,a,b);}x(Y,hd);e=Y.prototype;e.Mb=function(a,b){if(!a)return(a=Y.L.Mb.call(this))&&this.Ac&&(this.Gd=h.setTimeout(u(this.Nb,this),this.Ac)), a;this.cc.enqueue(void 0!==b?b:100,a);this.Nb();};e.Nb=function(){for(var a=this.cc;0<a.C();){var b=this.Mb();if(b)a.ab().apply(this,[b]);else break}};e.nc=function(a){Y.L.nc.call(this,a);this.Nb();};e.Cb=function(){Y.L.Cb.call(this);this.Nb();};
e.F=function(){Y.L.F.call(this);h.clearTimeout(this.Gd);this.cc.clear();this.cc=null;};function Z(a,b,c,d){this.Jf=a;this.Ab=!!d;Y.call(this,b,c);}x(Z,Y);Z.prototype.xc=function(){var a=new V,b=this.Jf;b&&b.forEach(function(b,d){a.headers.set(d,b);});this.Ab&&a.Je(!0);return a};Z.prototype.Sc=function(a){return!a.Ka&&!a.Ba()};cd.prototype.createWebChannel=cd.prototype.cf;W.prototype.send=W.prototype.send;W.prototype.open=W.prototype.open;W.prototype.close=W.prototype.close;bc.NO_ERROR=0;bc.TIMEOUT=8;bc.HTTP_ERROR=6;cc.COMPLETE="complete";ec.EventType=Q;Q.OPEN="a";Q.CLOSE="b";Q.ERROR="c";Q.MESSAGE="d";H.prototype.listen=H.prototype.nb;Z.prototype.getObject=Z.prototype.Mb;Z.prototype.releaseObject=Z.prototype.og;V.prototype.listenOnce=V.prototype.Oc;V.prototype.getLastError=V.prototype.Hc;V.prototype.getLastErrorCode=V.prototype.Ud;
V.prototype.getStatus=V.prototype.za;V.prototype.getStatusText=V.prototype.Yd;V.prototype.getResponseJson=V.prototype.yf;V.prototype.getResponseText=V.prototype.ya;V.prototype.getResponseText=V.prototype.ya;V.prototype.send=V.prototype.send;var src={createWebChannelTransport:fd,ErrorCode:bc,EventType:cc,WebChannel:ec,XhrIoPool:Z};
var src_1 = src.createWebChannelTransport;
var src_2 = src.ErrorCode;
var src_3 = src.EventType;
var src_4 = src.WebChannel;
var src_5 = src.XhrIoPool;

export default src;
export { src_1 as createWebChannelTransport, src_2 as ErrorCode, src_3 as EventType, src_4 as WebChannel, src_5 as XhrIoPool };
